@tailwind base;
@tailwind components;
@tailwind utilities;
*{
  margin: 0;
  padding: 0;
  box-sizing: 0;
}
body {
  overflow-x: hidden;
}
.shad{
  filter: drop-shadow(0px 2px 2px rgba(37, 37, 38, 0.175));
   /* box-shadow: 0px 2px 2px rgba(37, 37, 38, 0.175) ; */
}
.active{color:black}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #454545;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
  margin-top: 45vh;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "lucida";
  src: url(./asset/fonts/Lucida\ Sans\ Unicode.ttf);
}
@font-face {
  font-family: "refrence";
  src: url(./asset/fonts/MS\ Reference\ Sans\ Serif.ttf);
}
.ref{
  font-family: "refrence"
}
.luc{
  font-family: "lucida"
}
.seg{
  font-family: 'Segoe UI';
}
.dd:hover {
    animation-name: vibrate;
  animation-duration: 1s;

}

@keyframes vibrate {
    0% {
        padding-top: 0px;
        transform: rotate(0deg)
    }
    25% {
        padding-top: -7px;
               transform: rotate(90deg)
    }
    50% {
        padding-top: 0px;
        transform: rotate(180deg)
    }
    75% {
        padding-top: 7px;
        transform: rotate(270deg)
    }
    100% {
        padding-top: 0px;
        transform: rotate(360deg)
    }
  }
